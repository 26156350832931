body {
    overflow: overlay;
}

.react-international-phone-input {
    height: 4.2rem !important;
}

.react-international-phone-country-selector-button {
    height: 4.2rem;
}
